import { prtgSensorStatus } from '../utils/dict_prtg';

export function getBasicInfoNetwork(networkInfo, name) {
  const infoToReturn = { name, status: -1, children: [], sensors: [] };

  // Procesar sensores del nivel actual
  const sensors = networkInfo['sensors'] || [];
  sensors.forEach((sensor) => {
    const sensorStatus = prtgSensorStatus[sensor['status']];
    infoToReturn['sensors'].push({
      name: sensor['sensor'],
      status: sensorStatus,
    });
    if (sensorStatus > infoToReturn['status']) {
      infoToReturn['status'] = sensorStatus;
    }
  });

  // Procesar hijos del nivel actual
  if (Array.isArray(networkInfo.children)) {
    for (let child of networkInfo.children) {
      if (child && child.name) {
        const { toInfoReturn: childInfo, status: childStatus } = getBasicInfoNetwork(child, child.name);
        infoToReturn['children'].push(childInfo);

        // Actualizar el estado si un hijo tiene un mayor status
        if (childStatus > infoToReturn['status']) {
          infoToReturn['status'] = childStatus;
        }
      }
    }
  }

  return { toInfoReturn: infoToReturn, status: infoToReturn['status'] };
}

export function getObjectDevices(groups) {
  let completeObject = {};

  groups.forEach((group) => {
    const copyGroup = JSON.parse(JSON.stringify(group));
    const [deviceName] = copyGroup['name'].split(' (');
    delete copyGroup['children'];
    if (group['children'] && group['children'].length) {
      const responseCompleteObject = getObjectDevices(group['children']);
      completeObject = { ...completeObject, ...responseCompleteObject };
    } else {
      completeObject[deviceName] = copyGroup;
      completeObject[deviceName] = { ...completeObject[deviceName], ...copyGroup };
    }
  });
  return completeObject;
}

export function getInfoGroups(infoGroups, deviceGroupRelationships) {
  const infoRedes = {};
  deviceGroupRelationships.forEach((rlship) => {
    const [group, device] = rlship.split(';');
    if (!infoRedes[group]) {
      infoRedes[group] = { name: group, children: [] };
    }
    const copyInfoGroups = JSON.parse(JSON.stringify(infoGroups));
    let result = copyInfoGroups;
    if (copyInfoGroups[device]) {
      infoRedes[group]['children'].push(copyInfoGroups[device]);
    }
  });
  return infoRedes;
}

export function getHostData(hostData, hosts){
  const filteredData = Object.values(hostData).filter(host => hosts.includes(host.name));
  const processedData = filteredData.map(host =>{
    const perfData = host.perf_data;
    if(perfData){
      const arrayPerfData = perfData.split(" ");
      const pingData = {};
      arrayPerfData.forEach((perf_data) =>{
        const data = perf_data.split(";");
        let temp = data[0].split("=");
        if(temp[0] === "rta"){
          pingData["Response time"]= temp[1];
        }
        else if(temp[0] === "pl"){
          pingData["Packet loss"] = temp[1];
        }
      })
      return {...host, ...pingData};
    }
    else{
      return host;
    }
  })
  return processedData;
}

export function getServiceData(serviceData, hosts){
  const filteredData = serviceData.filter(host => hosts.includes(host.host_name));
  const parsedData = filteredData.map(host => ({
    ...host,
    services: JSON.parse(host.services)
  }))
  const processedData = parsedData.map(host =>{
    const host_name = host.host_name;
    const services = host.services;
    const servicesDataProccesed = {};
    services.forEach((service) => {
      const perfData = service.perf_data;
      if(perfData){
        if(service.description.startsWith("Uso de Memoria")){
          const arrayPerfData = perfData.split(" ");
          const memoryUtilizationData = arrayPerfData[2];
          const arrayMemoryPercentage = memoryUtilizationData.split(";");
          const memoryPercentage = arrayMemoryPercentage[0].split("=");
          servicesDataProccesed["Memoria"] = {data: memoryPercentage[1], status: service.state};
        }
        if(service.description.startsWith("Uso de CPU")){
          const arrayPerfData = perfData.split(" ");
          const cpuUtilizationData = arrayPerfData[1];
          const arrayCPUPercentage = cpuUtilizationData.split(";");
          const cpuPercentage = arrayCPUPercentage[0].split("=");
          servicesDataProccesed["CPU"] = { data: cpuPercentage[1], status: service.state};
        }
        if(service.description.startsWith("CPU use")){
          const arrayCPUPercentage = perfData.split(";");
          const cpuPercentage = arrayCPUPercentage[0].split("=");
          servicesDataProccesed["CPU"] = { data: cpuPercentage[1] + "%", status: service.state};
        }
        if(service.description.startsWith("ORACLE")){
          const state = service.state;
          servicesDataProccesed["Oracle"] = state;
        }
        if(service.description.startsWith("Disco")){
          const regex = /'[^']*'|[^\s]+/g;
          const arrayDiskPercentage = perfData.match(regex);
          const diskPercentage = arrayDiskPercentage.at(-1).split("=")[1].split(";");
          servicesDataProccesed["Disco"] = {data: diskPercentage[0], status: service.state};
        }

      }
    });
    return {host_name: host_name, ...servicesDataProccesed}
  })
  return processedData;
}

export function removeCircularReferences(obj, seen = new WeakSet()) {
  if (obj && typeof obj === 'object') {
    if (seen.has(obj)) {
      return '[Circular]';
    }
    seen.add(obj);
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        obj[key] = removeCircularReferences(obj[key], seen);
      }
    }
    seen.delete(obj);
  }
  return obj;
}

export function processData(data) {
  const series = {};
  data.series.forEach((serie) => {
    if (serie.fields && serie.name && serie.name.includes(':')) {
      serie.fields.forEach((field) => {
        const values = field.values.length ? field.values : [];
        const [category, host] = serie.name.split(':');
        if (!series[category]) {
          series[category] = {};
        }
        if (!series[category][host]) {
          series[category][host] = [];
        }
        values.forEach((value, index) => {
          if (!series[category][host][index]) {
            series[category][host].push({});
          }
          if (field.name === 'children') {
            series[category][host][index][field.name] = JSON.parse(value);
          } else {
            series[category][host][index][field.name] = value;
          }
        });
      });
    }
  });
  return JSON.parse(JSON.stringify(series));
}

import React, { useEffect, useState, useRef } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import {
  getBasicInfoNetwork,
  getObjectDevices,
  getInfoGroups,
  getHostData,
  // getServiceData,
  // @ts-ignore
} from 'api/datasource.js';

// @ts-ignore
import {getData} from 'api/backend.js' 

import { ThemeProvider } from './ThemeContext';
// @ts-ignore
import { lims_host_coya } from '../utils/lims_hosts';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './SimplePanel.css';
// @ts-ignore
import Network from './Network/Network';
import Lims from './Lims/Lims';
import Main from '../views/Main/Main';

interface Props extends PanelProps<SimpleOptions> {
  options: any;
  data: any;
  width: number;
  height: number;
}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height }) => {
  const [networkData, setNetworkData] = useState({});
  const [hostsLims, setHostsLims] = useState([]);
  const [upTimeGlobal, setUpTimeGlobal] = useState([]);
  const [selectedDashboard, setSelectedDashboard] = useState('main');
  const containerRef: any = useRef(null);

  function changeDashboard(dashboard: string) {
    setSelectedDashboard(dashboard);
  }

  useEffect(() => {
    function adjustScale() {
      // Tamaño actual de la ventana
      const screenWidth = width;
      const screenHeight = height;

      // Tamaño base del diseño
      const baseWidth = 1920;
      const baseHeight = 1000;

      // Calcula las escalas para ancho y alto
      const scaleX = screenWidth / baseWidth;
      const scaleY = screenHeight / baseHeight;

      // Establece un límite superior para la escala
      const maxScale = 1.5; // Este valor puede ser ajustado para pantallas grandes
      const scale = Math.min(scaleX, scaleY, maxScale);

      // Aplica la escala al contenedor
      if (containerRef.current) {
        containerRef.current.style.transform = `scale(${scale})`;
        containerRef.current.style.transformOrigin = 'top left'; // Escala desde la esquina superior izquierda
      }
    }

    // Llama a la función cuando cambie el tamaño de la ventana
    adjustScale();

    // Escucha los cambios de tamaño de la ventana
    window.addEventListener('resize', adjustScale);

    // Limpieza de la función en caso de que el componente se desmonte
    return () => window.removeEventListener('resize', adjustScale);
  }, [width, height]);

  async function  getDataBackend(){
    const backendData = await getData();
    const infoFromApi = backendData.relationships;
    const infoNagiosHost = backendData.hosts_services;
    const infoUptimeGlobal = backendData.uptime_global;

    if (infoFromApi) {
      const deviceGroupRelationships = options['device_by_group'].split('\n');
      const infoGroups = getObjectDevices(infoFromApi);
      const infoGroupsToGraph = getInfoGroups(infoGroups, deviceGroupRelationships);
      const auxInfoNetw = {};
      for (const device in infoGroupsToGraph) {
        const groupInfo = infoGroupsToGraph[device];
        const { toInfoReturn: infoNetwork } = getBasicInfoNetwork(groupInfo, device);
        // @ts-ignore
        auxInfoNetw[infoNetwork['name']] = infoNetwork;
      }
      // @ts-ignore
      setNetworkData(auxInfoNetw);
    }
    if (infoNagiosHost) {
      const hostsLimsTemp = getHostData(infoNagiosHost, lims_host_coya);
      setHostsLims(hostsLimsTemp);
    }
    if (infoUptimeGlobal) {
      const uptimeGlobalTemp = infoUptimeGlobal.map((uptimeObject: any) => {
        return { name: uptimeObject.name, uptime: `${uptimeObject.uptime * 100}%` }
      })
      setUpTimeGlobal(uptimeGlobalTemp);
    }

  }

  // @ts-ignore
  useEffect(() => {
    getDataBackend();
    // eslint-disable-next-line
  }, [data]);

  return (
    <ThemeProvider>
      <div
        ref={containerRef}
        style={{ width: `${width}px`, height: `${height}px`, minHeight: '1000px', minWidth: '1920px' }}
      >
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.7.2/css/all.min.css" />
        <div className="network-dashboard">
          {selectedDashboard === 'network' && <Network networkData={networkData} changeDashboard={changeDashboard} />}
          {selectedDashboard === 'lims' && (
            <Lims
              hostsLims={hostsLims}
              // servicesLims={servicesLims}
              changeDashboard={changeDashboard}
              relationships={options.services_lims_relationships}
              components={JSON.parse(options.json_structure_lims)}
            />
          )}
          {selectedDashboard === 'main' && <Main changeDashboard={changeDashboard} upTimeGlobal={upTimeGlobal}/>}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default SimplePanel;

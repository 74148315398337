// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Manrope);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.network-dashboard {
  width: 100%;
  height: 100%;
  font-family: 'Manrope';
  background-color: #f8faff;
}

.bg-darker{
  background-color: #2f3449 !important;
}

.border-r-darker{
  border-right-color: #2f3449!important;
}

.bg-dark{
  background-color: #2c3145 !important;
}

.box-shadow-dark{
  box-shadow: #2c3145 0px 3px 8px !important;
}

.bg-grey{
  background-color: #3e435a !important;
}

.border-b-grey{
  border-bottom-color: #3e435a !important;
}

.box-shadow-grey{
  box-shadow: #3e435a 0px 0px 0px 1px !important;
}

.color-b7{
  color: #ebeef6 !important;  
}

.bg-dark-blue{
  background-color: #24293b !important;
}

.bg-light-grey{
  background: #32384e !important;
}

.bg-dark-grey{
  background: #3e435a !important;
}

.bg-transparent{
  background: transparent !important;
  box-shadow: 0px 0px transparent !important;
}`, "",{"version":3,"sources":["webpack://./components/SimplePanel.css"],"names":[],"mappings":"AAEA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,yBAAyB;AAC3B;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,8CAA8C;AAChD;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,kCAAkC;EAClC,0CAA0C;AAC5C","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Manrope');\n\n.network-dashboard {\n  width: 100%;\n  height: 100%;\n  font-family: 'Manrope';\n  background-color: #f8faff;\n}\n\n.bg-darker{\n  background-color: #2f3449 !important;\n}\n\n.border-r-darker{\n  border-right-color: #2f3449!important;\n}\n\n.bg-dark{\n  background-color: #2c3145 !important;\n}\n\n.box-shadow-dark{\n  box-shadow: #2c3145 0px 3px 8px !important;\n}\n\n.bg-grey{\n  background-color: #3e435a !important;\n}\n\n.border-b-grey{\n  border-bottom-color: #3e435a !important;\n}\n\n.box-shadow-grey{\n  box-shadow: #3e435a 0px 0px 0px 1px !important;\n}\n\n.color-b7{\n  color: #ebeef6 !important;  \n}\n\n.bg-dark-blue{\n  background-color: #24293b !important;\n}\n\n.bg-light-grey{\n  background: #32384e !important;\n}\n\n.bg-dark-grey{\n  background: #3e435a !important;\n}\n\n.bg-transparent{\n  background: transparent !important;\n  box-shadow: 0px 0px transparent !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import './LocationMainCard.css';
import Factory from '../../img/factory.png';
import SimpleStatusCard from '../SimpleStatusCard/SimpleStatusCard'
// import { colorStatusDictionary } from '../../utils/color';
import { useThemeContext } from '../ThemeContext';

function LocationMainCard({ name, type, status, logo, changeDashboard, uptime }) {
  // console.log(status);
  const { isDarkMode } = useThemeContext();
  return (
    <div className={ isDarkMode ? 'location-wrapper bg-grey' :'location-wrapper'}>
        <div className='line' />
        <div className={isDarkMode ? 'location-info-wrapper border-r-darker': 'location-info-wrapper'}>
            <div className='status'/>
            <img src={logo} />
            <div className='location-info'>
                <img src={Factory} />
                <div className={isDarkMode ? "location-type color-b7" :"location-type"}>{type}</div>
                <div className={isDarkMode ? "location-name color-b7" : "location-name"}>{name}</div>
                {uptime && <div className={isDarkMode ? "location-type color-b7" : "location-type"}>Uptime: {uptime}</div>}
            </div>
        </div>
        <div className='location-services'>
            <SimpleStatusCard name="Redes" status="UNK" colorStatus={name != "Coya Sur"?"#C4C4C4": "#80bc00"} position={{height: "100px", position: "relative"}} onSelect={()=>changeDashboard("network")} />
            <SimpleStatusCard name="Aplicaciones críticas" status="WARNING" colorStatus={name != "Coya Sur"?"#C4C4C4": "#ed4c5c"} position={{height: "100px", position: "relative"}} onSelect={()=>changeDashboard("lims")}/>
            <SimpleStatusCard name="Servicios críticos" status="UNK" colorStatus={"#C4C4C4"} position={{height: "100px", position: "relative"}} />
            <SimpleStatusCard name="Dispositivos" status="UNK" colorStatus={"#C4C4C4"} position={{height: "100px", position: "relative"}} />
        </div>
    </div>
  );
}

export default LocationMainCard;

import Logo from '../img/sqm-logo.svg';
import BackArrow from '../img/back-arrow.png';
import FaenaNV from '../img/faena-nv.png';
import PlantaCDS from '../img/planta-cds.png';
import PlantaPDV from '../img/planta-pdv.png';
import PlantaSDA from '../img/planta-sda.png';
import PlantaSDC from '../img/planta-sdc.png';
import OficinaStgo from '../img/oficina-stgo.png';
import PlantaPBca from '../img/planta-pblca.png';
import PuertoToco from '../img/puerto-tocopilla.png';
import Factory from '../img/factory.png';

const icons = {
  logo: Logo,
  back: BackArrow,
  FaenaNV: FaenaNV,
  PlantaCDS: PlantaCDS,
  PlantaPDV: PlantaPDV,
  PlantaSDA: PlantaSDA,
  PlantaSDC: PlantaSDC,
  OficinaStgo: OficinaStgo,
  PlantaPBca: PlantaPBca,
  PuertoToco: PuertoToco,
  Factory: Factory,
};

export { icons };

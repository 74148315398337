import React, { useState } from 'react';
import './Card.css';
import PercentageBar from '../PercentageBar/PercentageBar';
import { useThemeContext } from '../ThemeContext';
//@ts-ignore
import { statusColorDictionary } from '../../utils/color.js'

type Props = {
  children?: any;
  name?: string;
  colorStatus?: any;
  style?: any;
  classes?: any;
  modifier?: string;
  onSelect?: () => void;
  info?: string;
  graphPercent?: number;
  arrayItems?: any;
};

const Card: React.FC<Props> = ({
  children,
  name,
  colorStatus,
  style,
  classes,
  modifier,
  onSelect = () => {},
  info,
  graphPercent,
  arrayItems = [],
}) => {
  
  const [showModal, setShowModal] = useState<Boolean>(false);
  
  const className = `card-container ${name ? 'solid' : ''} ${modifier ? modifier : ''}`;
  const { isDarkMode } = useThemeContext();
  const isArray = arrayItems && arrayItems.length > 1;
  let statusArray = "#80bc00";
  if(isArray){
    arrayItems.forEach((item: any) => {
      if(statusColorDictionary[statusArray] < statusColorDictionary[item.colorStatus]){
        statusArray = item.colorStatus;
      }
    });
  }
  let mainDivClass = className;
  if(isDarkMode && classes){
    mainDivClass += " " + classes;
  }
  if(isDarkMode){
    mainDivClass += ' bg-darker box-shadow-grey';
  }
  return (
    <>
      <div
        className={mainDivClass}
        style={{ ...style }}
        onClick={() => {
          onSelect();
          if (isArray) {
            setShowModal(true);
          }
        }}
      >
        <div className="header">
          <div className={isDarkMode ? 'title color-b7' : 'title'}>{name}</div>
          {(info && !isArray) && (
            <div className="info-status" style={{ color: colorStatus }}>
              {info}
            </div>
          )}
          {(!info && !isArray) && colorStatus && <div className="status" style={{ backgroundColor: colorStatus }}></div>}
          {isArray && <div className='triangle-status' style={{ backgroundColor: statusArray}}/>}
          {graphPercent && <PercentageBar percentage={graphPercent}></PercentageBar>}
        </div>
        <div className="content">{children}</div>
      </div>
      {showModal ? (
        <div className="modal" style={{ top: style.top, left: style.left }}>
          <div className="header-modal">
            <div className="service-name">{name}</div>
            <div className="close-icon" onClick={() => setShowModal(false)}>
              {'X'}
            </div>
          </div>
          <div className="body-modal">
            {arrayItems.map((item: any) => (
              <div className="item-modal" key={'index'}>
                <div className="label-modal">{item.name}</div>
                <div className="value-modal" style={{ color: item.colorStatus }}>
                  {item.info}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Card;

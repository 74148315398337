import React from 'react';

//@ts-ignore
import Toolbar from 'components/Toolbar/Toolbar';
//@ts-ignore
import LocationMainCard from 'components/LocationMainCard/LocationMainCard';

//@ts-ignore
import { colorStatusDictionary } from '../../utils/color';
//@ts-ignore
import { icons } from '../../utils/dictionaryIcons';
import './Main.css';
import GeoMap from 'img/map-chile.svg';
import GeoMapDark from 'img/map-chile-dark.svg';
import { useThemeContext } from '../../components/ThemeContext';

function Main({ changeDashboard, upTimeGlobal }: { changeDashboard: any, upTimeGlobal: any[] }) {
  const { isDarkMode } = useThemeContext();
  const locations: any = [
    { type: 'Faena', name: 'Nueva Victoria', iconName: 'FaenaNV', position: { top: '41px', left: '247px' } },
    { type: 'Planta', name: 'Coya Sur', iconName: 'PlantaCDS', position: { top: '201px', left: '249px' } },
    { type: 'Planta', name: 'Pedro de Valdivia', iconName: 'PlantaPDV', position: { top: '241px', left: '260px' } },
    { type: 'Puerto', name: 'Tocopilla', iconName: 'PuertoToco', position: { top: '232px', left: '186px' } },
    { type: 'Planta', name: 'Pampa Blanca', iconName: 'PlantaPBca', position: { top: '329px', left: '229px' } },
    { type: 'Oficina', name: 'Santiago', iconName: 'OficinaStgo', position: { top: '830px', left: '200px' } },
  ];

  function getLocations() {
    return locations.map((location: any, index: any) => {
      const uptimeObject = upTimeGlobal.find((uptimeObject: any) => uptimeObject.name === location.name);
      return (
        <LocationMainCard
          key={`${index}-location-main-card`}
          type={location.type}
          name={location.name}
          status={location.status}
          logo={icons[location.iconName]}
          changeDashboard={changeDashboard}
          uptime={uptimeObject ? uptimeObject.uptime : undefined}
        />
      )
    });
  }

  function getLocationsMap() {
    return locations.map((location: any, index: any) => (
      <div className="status-map" key={`${index}-lc-map`} style={location.position} />
    ));
  }

  return (
    <section className="main-container">
      <Toolbar title="Dashboard Geolocalización" changeDashboard={undefined} />
      <div className="main-content">
        <div className="map">
          {isDarkMode ? <img src={GeoMapDark} alt="GeoMapDark" /> : <img src={GeoMap} alt="GeoMap" />}
          {getLocationsMap()}

          {/* Lines */}
          {/* Lines Nueva Victoria */}
          <div className="line-map" style={{ top: '95px', right: '0px', width: '182px' }} />
          <div
            className="line-map"
            style={{ top: '80px', right: '173px', width: '44px', transform: 'rotate(45deg)' }}
          />
          {/* Lines Coya Sur */}
          <div className="line-map" style={{ top: '240px', right: '0px', width: '185px' }} />
          <div
            className="line-map"
            style={{ top: '230px', right: '184px', width: '27px', transform: 'rotate(45deg)' }}
          />
          {/* Lines Pedro de Valdivia */}
          <div className="line-map" style={{ top: '385px', right: '0px', width: '124px' }} />
          <div className="line-map" style={{ top: '335px', right: '125px', height: '50px' }} />
          <div
            className="line-map"
            style={{ top: '297px', right: '114px', width: '97px', transform: 'rotate(45deg)' }}
          />
          {/* Lines Tocopilla */}
          <div className="line-map" style={{ top: '530px', right: '0px', width: '153px' }} />
          <div className="line-map" style={{ top: '372px', right: '152px', height: '155px' }} />
          <div
            className="line-map"
            style={{ top: '311px', right: '131px', width: '164px', transform: 'rotate(45deg)' }}
          />
          {/* Lines Pampa Blanca */}
          <div className="line-map" style={{ top: '675px', right: '0px', width: '107px' }} />
          <div
            className="line-map"
            style={{ top: '611px', right: '85px', width: '180px', transform: 'rotate(45deg)' }}
          />
          <div className="line-map" style={{ top: '356px', right: '237px', height: '188px' }} />
          {/* Santiago */}

          <div className="line-map" style={{ top: '820px', right: '0px', width: '243px' }} />
          <div
            className="line-map"
            style={{ top: '825px', right: '245px', width: '12px', transform: 'rotate(-45deg)' }}
          />
        </div>


        <div className={isDarkMode ? 'locations bg-darker' : 'locations'}>{getLocations()}</div>
      </div>
    </section>
  );
}

export default Main;

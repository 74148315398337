import React, { useEffect, useState } from 'react';

import Card from '../Card/Card';

//@ts-ignore
import Toolbar from '../Toolbar/Toolbar';

//@ts-ignore
import { DashboardComponent } from '../../utils/info_lims';

//@ts-ignore
import { colorStatusDictionary } from '../../utils/color';
import './Lims.css';
import { useThemeContext } from '../ThemeContext';

//@ts-ignore
import { getHostsServices } from '../../rest/nagios.js';

function Lims({
  changeDashboard,
  relationships,
  hostsLims,
  components,
}: {
  changeDashboard: any;
  relationships: any;
  hostsLims: any;
  components: any;
}) {
  const [infoComponents, setInfoComponents] = useState(components);
  // const [hostsLims, setHostsLims] = useState<any[]>([]);
  const { isDarkMode } = useThemeContext();

  function getPercentage(output: any) {
    const regex = /([\d.]+)%/;
    const match = output.match(regex);
    if (match) {
      return parseFloat(match[1]);
    }
    return null;
  }

  const getMatchingComponentValue = (service_name: any, dictNewServices: any) => {
    const key = Object.keys(dictNewServices).find(k => service_name.startsWith(k));
    return key ? dictNewServices[key] : null;
  };
  

  // function modifySomeChild(dataArray: any, parentName: string | null, targetName: string, modifications: any) {
  //   return dataArray.map((item: any) => {
  //     if (item.host_name === parentName && item.children) {
  //       const updatedChildren = modifySomeChild(item.children, null, targetName, modifications);
  //       return { ...item, children: updatedChildren };
  //     }

  //     if (!parentName && item.name === targetName) {
  //       return { ...item, ...modifications };
  //     }

  //     if (item.children) {
  //       return {
  //         ...item,
  //         children: modifySomeChild(item.children, parentName, targetName, modifications),
  //       };
  //     }

  //     return { ...item };
  //   });
  // }

  //Finds the parent name in the array, and looks in his child with name : "" the target name to insert modifications
  //If Target name is null, the modifications are done in the parent
  function modifyNestedInArray(
    dataArray: any,
    parentName: string | null,
    targetName: string | null,
    modifications: any
  ) {
    return dataArray.map((item: any) => {
      if (item.host_name === parentName && targetName === null) {
        return { ...item, ...modifications };
      }

      if (item.host_name === parentName && item.children) {
        const updatedChildren = item.children.map((child: any) => {
          if (child.validator === '') {
            const updatedUnnamedChildChildren = child.children.map((grandChild: any) => {
              if (grandChild.name === targetName) {
                return { ...grandChild, ...modifications };
              }
              return { ...grandChild };
            });

            return { ...child, children: updatedUnnamedChildChildren };
          }
          return { ...child };
        });
        return { ...item, children: updatedChildren };
      }

      if (item.children && Array.isArray(item.children)) {
        return {
          ...item,
          children: modifyNestedInArray(item.children, parentName, targetName, modifications),
        };
      }

      return { ...item };
    });
  }

  useEffect(() => {
    let copyInfoComponents = infoComponents;
    if (hostsLims) {
      //hosts
      hostsLims.forEach((host: any) => {
        if (host.hasOwnProperty('state')) {
          copyInfoComponents = modifyNestedInArray(copyInfoComponents, host.name, null, { status: host.state });
        }
        if (host.hasOwnProperty('Response time')) {
          copyInfoComponents = modifyNestedInArray(copyInfoComponents, host.name, 'Response time', {
            info: host['Response time'],
            status: 0,
          });
        }
        if (host.hasOwnProperty('Packet loss')) {
          copyInfoComponents = modifyNestedInArray(copyInfoComponents, host.name, 'Packet loss', {
            info: host['Packet loss'],
            status: 0,
          });
        }
      });
      //services
      const tmpServicesLims: any = [];
      const dictNewServices: any = {};
      const arrayRelationships = relationships.split('\n').map((eachRlt: any) => {
        const arrayInfoRlt = eachRlt.split(',');
        return { hostname: arrayInfoRlt[0], serviceNagios: arrayInfoRlt[1], serviceDashboard: arrayInfoRlt[2] };
      });
      arrayRelationships.forEach((rlt: any) => {
        if (!dictNewServices[rlt.hostname]) {
          dictNewServices[rlt.hostname] = {};
        }
        dictNewServices[rlt.hostname][rlt.serviceNagios] = rlt.serviceDashboard;
      });

      
      hostsLims.forEach((htSrv: any) => {
        if (dictNewServices[htSrv.name]) {
          const newService: any = { host_name: htSrv.name };
          htSrv.services.forEach((srv: any) => {  

            const serviceDisplayName = getMatchingComponentValue(srv.description, dictNewServices[htSrv.name]);
            if (serviceDisplayName) {
              const usePercentage = getPercentage(srv.plugin_output);
              if (!newService[serviceDisplayName]) {
                newService[serviceDisplayName] = {
                  status: srv.state,
                  data: usePercentage ? `${usePercentage}%` : '',
                  arrayItems: [],
                };
              }
              newService[serviceDisplayName]['arrayItems'].push({
                name: srv.description,
                info: usePercentage ? `${usePercentage}%` : '',
                colorStatus: colorStatusDictionary[srv.state],
              });
            }
          });
          tmpServicesLims.push(newService);
        }
      });
      console.log(tmpServicesLims)
      tmpServicesLims.forEach((service: any) => {
        Object.keys(service).forEach((key) => {
          if (key === 'host_name') {
            return;
          }
          const data =
            service[key]?.data !== undefined
              ? { info: service[key].data, status: service[key].status, arrayItems: service[key]['arrayItems'] }
              : { status: service[key]?.status, arrayItems: service[key]['arrayItems'] };

          copyInfoComponents = modifyNestedInArray(copyInfoComponents, service.host_name, key, data);
        });
      });
    }
    setInfoComponents(copyInfoComponents);
    // eslint-disable-next-line
  }, [hostsLims]);

  function getDashboardItems(parent: DashboardComponent) {
    const items: any[] = [];
    parent?.children?.forEach((child: any, index: any) => {
      if (child.children) {
        items.push(
          <div className="content-item" key={`${index}-lims-item`}>
            <Card
              //@ts-ignore
              name={child.host_name ? `${child.name} (${child.host_name})` : child.name}
              //status={child.status}
              arrayItems={child.arrayItems}
              info={child.info}
              graphPercent={child.graphPercent}
              // icon={child.icon}
              colorStatus={colorStatusDictionary[child.status]}
              style={child.style}
              classes={child.darkStyle}
              modifier={child.modifier}
            >
              {getDashboardItems(child)}
            </Card>
          </div>
        );
      } else {
        items.push(
          <div className="content-item" key={`${index}-lims-item`}>
            <Card
              //@ts-ignore
              name={child.host_name ? `${child.name} (${child.host_name})` : child.name}
              arrayItems={child.arrayItems}
              //status={child.status}
              info={child.info}
              graphPercent={child.graphPercent}
              // icon={child.icon}
              colorStatus={colorStatusDictionary[child.status]}
              style={child.style}
              classes={child.darkStyle}
              modifier={child.modifier}
            />
          </div>
        );
      }
    });

    return items;
  }
  function getDashboardContent() {
    const dashboardItems: any[] = [];
    infoComponents.forEach((groupInfo: any) => {
      if (groupInfo.children) {
        dashboardItems.push(
          <Card
            key={`${groupInfo.name}-simple-status-card`}
            name={groupInfo.name}
            info={groupInfo.info}
            graphPercent={groupInfo.graphPercent}
            colorStatus={colorStatusDictionary[groupInfo.status]}
            style={groupInfo.style}
            classes={groupInfo.darkStyle}
            modifier={groupInfo.modifier}
          >
            {getDashboardItems(groupInfo)}
          </Card>
        );
      }
    });
    return dashboardItems;
  }
  return (
    <section className="lims-container">
      <Toolbar title={`Dashboard LIMS`} location={'Coya Sur'} changeDashboard={() => changeDashboard('main')} />
      <div className={isDarkMode ? 'lims-content bg-dark' : 'lims-content'}>
        <div className="main-content">
          {getDashboardContent()}
          {/* COYASUR:BD - AZURE:LIMS  */}
          <div
            className="solid-horizontal blue-solid"
            style={{ position: 'absolute', top: '350px', left: '1090px', width: '30px' }}
          ></div>
          <div
            className="solid-vertical blue-solid"
            style={{ position: 'absolute', top: '350px', left: '1090px', height: '400px' }}
          ></div>
          <div
            className="solid-horizontal blue-solid"
            style={{ position: 'absolute', top: '750px', left: '800px', width: '294px' }}
          ></div>
          {/* COYASUR:APP - COYASUR:BD  */}
          <div
            className="solid-vertical blue-solid"
            style={{ position: 'absolute', top: '604px', left: '490px', height: '38px' }}
          ></div>
          {/* COYASUR:BD - AZURE:BD  */}
          <div
            className="solid-horizontal blue-solid"
            style={{ position: 'absolute', top: '800px', left: '800px', width: '310px' }}
          ></div>
        </div>
      </div>
    </section>
  );
}

export default Lims;
